import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export interface AlertProps extends MuiAlertProps {
    scrollIntoViewDisabled?: boolean;
    severity: 'error' | 'warning' | 'info' | 'success';
    text?: string | JSX.Element;
    alertTitle?: string;
    action?: React.ReactNode;
    closeable?: boolean;
    onClose?(): void;
}

const StyledAlert = styled(MuiAlert)(() => ({
    maxWidth: 1000,
}));

const StyledAlertTitle = styled(AlertTitle)(() => ({
    fontWeight: 700,
}));

export const Alert: React.FC<AlertProps> = ({
    severity,
    text,
    alertTitle,
    action,
    closeable,
    scrollIntoViewDisabled = false,
    onClose,
    ...props
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (ref && ref.current && !scrollIntoViewDisabled) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
        }
    }, []);
    return open ? (
        <StyledAlert
            ref={ref}
            {...props}
            severity={severity}
            action={action}
            iconMapping={{
                success: <CheckCircleOutlineIcon />,
            }}
            onClose={
                severity === 'info' || closeable
                    ? () => {
                          setOpen(false);
                          onClose?.();
                      }
                    : undefined
            }
        >
            {alertTitle && <StyledAlertTitle>{alertTitle}</StyledAlertTitle>}
            {text}
        </StyledAlert>
    ) : null;
};
